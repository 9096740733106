<template>
    <div class=container>
        <table class=table width="73%" border="0" cellspacing="30" cellpadding="0">
          <tbody><tr>
            <td><div align="center"> <font size="+2">BTF Measurements</font></div></td>
          </tr>
          <tr>
            <td valign="baseline">We introduce the term BTF (bidirectional texture function) to represent the appearance of texture as a function of viewing and illumination direction. When a surface is macroscopically rough, appearance has a complex dependence on illumination and viewing direction. We measured the BTF of 61 real-world surfaces by recording images of each surface with 205 different combinations of viewing and illumination directions. A <router-link to="/repository/CURET/table"> table </router-link> is provided which gives the illumination and viewing directions associated with each of the 205 images. The images are stored as 24-bit BMP files, and can readily be <router-link to="/repository/CURET/cal"> radiometrically calibrated </router-link>.
<p></p>
<!--<P> The 205 images for each sample can be downloaded from the directories <IT> sample01, sample02, ... sample61, located </IT> <a href="../../../exclude/curet/data/" rel="nofollow">here</a>.--> 
<p></p>
<p> Any of the 205 images for each sample can be downloaded individually by clicking on the appropriate sample in the <router-link to="/repository/CURET/graphmap"> graphical sample-list </router-link> or the <router-link to="/repository/CURET/txtmap"> text-only sample-list </router-link>. </p>
<p> An illustration of <router-link to="/repository/CURET/cly"> 3D texture mapping </router-link> using the images of the database is provided. </p></td>
          </tr>
          <tr>
            <td valign="baseline"><div align="center">              <em>
              <div align="left">                </div>
            </em>
                <div align="left"></div>
                <div align="left"><hr align="left" size="4">
                  <p><router-link to="/repository/CURET"> [Return to Database Home Page] </router-link></p>
                  </div>
            </div></td>
          </tr>
        </tbody></table>
    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>